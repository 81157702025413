@import '@angular/material/prebuilt-themes/indigo-pink.css';
/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-body-wrapper {
  height: 100%;
}

.spacer {
  flex: 1;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.snackbar-error {
  background-color: darkred;
  color: lightgray;
}

.mat-header-cell {
  font-weight: bold;
  font-size: 1em;
  color: black;
}

.mat-cell, .mat-header-cell, .mat-footer-cell {
  padding-left: clamp(5px, 5%, 10px) !important;
  padding-right: clamp(5px, 5%, 10px) !important;
}

.file-viewer-dialog {
  width: 30vw;
  height: 40vw;
}

.tooltip-red {
  background: #b71c1c;
}